import * as React from "react"
import { createGlobalStyle } from 'styled-components';
import { getConfigDefaults, GlobalConfig, validateConfig } from "../../locust/composables/Layout";
import { css } from "glamor";
import X from "../../locust/composables/X";
import SpacerV from "../../locust/sections/SpacerV";
import { Button } from "@mui/material";
import { auth, db, isLoggedIn } from "../../../firebase-config";
import LinkDelayed from "../../locust/composables/LinkDelayed";
import CobraLayout from "../../client-ui/shared/LayoutCobra";
import AuthManager from "../../locust/composables/AuthManager";
import { SectionTitle } from "../../client-ui/checkout/CheckoutSectionTitle";
import OrderSubscription from "../../client-ui/orders/OrderSubscription";
import { OrderDataModel } from "../../client-ui/orders/Models";
import EntryItemText from "../../client-ui/shared/EntryItemText";
import OrdersInfoBox from "../../client-ui/orders/OrdersInfoBox";
import { toDateString } from "../../client-ui/orders/Shared";
import { getProductParamsByURL } from "../../client-ui/products/Shared";
import { Page, to } from "../../client-ui/shared/SiteLinks";
import { LoaderFullScreenSwitch } from "../../client-ui/shared/LoaderFullScreen";
import NoticeAndLink from "../../client-ui/shared/NoticeAndLink";
import OrderProductItem from "../../client-ui/orders/OrderProductItem";
import { doc, getDoc } from "firebase/firestore";
import { getUserEmail } from "../../service/user-service";
import { ENABLE_ERROR_LOGGING } from "../../settings/project-settings";


export const GlobalStyle = createGlobalStyle`
*{
    margin:0;
    padding:0;
}
html, body {
    overflow-y: visible;
    -webkit-overflow-scrolling: touch;
}
body {
    position: relative;
    width: 100%;
    background-image: radial-gradient(farthest-corner at max(50%,150px) 50%,
        rgba(249, 249, 249, 0.5) 0%, rgba(249, 249, 249, 0.5) 20%, rgba(249, 249, 249, 0.5) 70%,#dae5ea 100%);
        height : 2000px
}
img{
    max-width:100%;
}  
`;

const breadcrumb = [
    { value : 'Home', url : to(Page.HOME)  },
    { value : 'Orders', url : `/orders` },
]

const subscriptionDictionary : any = {
    'price_1LJdfWA2K1dGX0mD5gUb1KT0' : 'Protection Plan',
    'price_1LICz1A2K1dGX0mDFbxiJNAz' : 'Subscription',
    'price_1LIDUEA2K1dGX0mDIADVfjF4' : 'Subscription'
}

const PageContainer = () => {
    const [loggedIn, setLoggedIn ] = React.useState(false)

    return (
        <>
        <AuthManager setLoggedIn={setLoggedIn}/>
        <CobraLayout headerType="store" loggedIn={loggedIn} breadcrumb={breadcrumb}>        
            
            <Orders/>
    
        </CobraLayout>
        
        
        </>
    )
}
export default PageContainer


const Orders = () => {

    const [ data, setData ] = React.useState<any>(undefined)
    //const [ customer, setCustomer ] = React.useState<any>(false)
    React.useEffect(()=>{
        auth.onAuthStateChanged( async ()=>{
            const loggedIn : boolean = isLoggedIn()
            if( loggedIn ){
                const orderRef      : any = doc(db, `orders/${auth.currentUser?.uid}`)
                const orderData     : any = await getDoc(orderRef).then(x=>x.data())
                //const customersRef  : any = doc(db, `customers/${auth.currentUser?.uid}`)
                //const customerData  : any = await getDoc(customersRef).then(x=>x.data())

                if( ENABLE_ERROR_LOGGING ) console.log(orderData.customer)
                if( ENABLE_ERROR_LOGGING ) console.log( auth.currentUser?.uid )
                if( ENABLE_ERROR_LOGGING ) console.log( getUserEmail() )
                if( ENABLE_ERROR_LOGGING ) console.log( orderData )
                setData( orderData )


                
                


                //setCustomer( customerData )             
            }
        })
    },[])

    //const on = ( value : number ) => getConfigDefaults().onMedia( value )
    //const NO_ORDER_DATA = data === undefined 
    //alert(NO_ORDER_DATA)
    return (

        <>  

        {/* { data === undefined && <>0</>}
        { data === false && <>1</>}
        { data && <>2</>} */}

            <SpacerV h={50}/>
            <X>
                <SectionTitle style={{ width : '100%', marginTop : -20}}>
                    <p style={{ fontSize : 36, fontWeight : 800 }}>Orders</p>
                </SectionTitle>
            </X>    
            
            <LoaderFullScreenSwitch switch={data == undefined } y={-400}>
                { data === false && <>
                    
                    <NoticeAndLink
                        href={to(Page.HOME)}
                        value={"Return to Store"}
                    >
                        You have no orders.
                    </NoticeAndLink>
                
                </>}

                { data   && 
                    <>
                        <X>
                            <div {...css({
                                width : '100%',
                                marginLeft : 8,
                                maxWidth : 800,
                            })}>
                        
                            { Object.keys(data).map(( keyValue : string, index : number )=>{
                                const orderData : any = data[keyValue]
                                const type : any = orderData.type
                                const isProduct : boolean = type === 'product'
                                if( isProduct ){

                                    const customer : any = data.customer
                                    const productData : OrderDataModel = {
                                        id: orderData.order.desc,
                                        shipping: {
                                            addressShipping: customer.addressShipping,
                                            addressShippingTwo: customer.addressShippingTwo,
                                            firstNameShipping: customer.firstNameShipping,
                                            lastNameShipping: customer.lastNameShipping,
                                            cityShipping: customer.cityShipping,
                                            stateShipping: customer.stateShipping,
                                            zipcodeShipping: customer.zipcodeShipping
                                        },
                                        date: toDateString(orderData.created),
                                        name: orderData.order.name,
                                        product: orderData.order.id,
                                        total: String(Number(orderData.amount)/100)
                                    }
                                    return <OrderProduct key={index} orderData={productData}/>
                                }

                                const isSubscription : boolean = type === 'subscription'
                                if( isSubscription ){
                                    
                                    const subscriptionData : OrderDataModel = {
                                        id: "subscription",
                                        shipping: {
                                            addressShipping: "",
                                            addressShippingTwo: "",
                                            firstNameShipping: "",
                                            lastNameShipping: "",
                                            cityShipping: "",
                                            stateShipping: "",
                                            zipcodeShipping: ""
                                        },
                                        status : orderData.status,
                                        date: toDateString(orderData.created),
                                        name: subscriptionDictionary[orderData.priceId],
                                        product: subscriptionDictionary[orderData.priceId],
                                        total: String(Number(orderData.amount)/100)
                                    }
                                    

                                    return <OrderSubscription 
                                        type={orderData.name} 
                                        orderData={subscriptionData}
                                        subscription={{ 
                                            start : orderData.subscription.current_period_start, 
                                            end : orderData.subscription.current_period_end, 
                                        }}
                                    />

                                }                    

                            })}
                            
                            </div>
                        </X>
                    </>
                }

                


            </LoaderFullScreenSwitch>
            
        </>
       
    )
}






export const OrderProduct = ( props :{ orderData : OrderDataModel }) => {

    const height = 'auto'
    const productData : any =  getProductParamsByURL('icy-inferno')
    const globals : GlobalConfig = getConfigDefaults()
    const onBreakOne : string = globals.onMedia(500)
    const onBreakTwo : string = globals.onMedia(300)
    
    const thumbnail = {
        verticalAlign : 'top',
        width : 200, 
        display : 'inline-block',
        backgroundColor : '',
        minHeight : 100,
        height : '100%',
        [onBreakOne]:{ 
            backgroundColor : '',
            width : '100%',
        }, 
        
    }
    const product = {
        verticalAlign : 'top',
        marginLeft: -20,
        width : `calc(100% - ${180}px)`, 
        display : 'inline-block',
        minHeight : 100,
        backgroundColor : '',
        [onBreakOne]:{ 
            backgroundColor : '',
            width : '100%',
            marginLeft : 10 
        }, 
        
    }
    
    return (
        <OrdersInfoBox style={{ 
            height : height, 
            paddingBottom : 20, 
            width : 'calc(100% - 45px)', 
            maxWidth : '',
            [onBreakTwo] : {
                width : 360 - 60,
            }
        }}>
            
                <div style={{ height : 40, width : '100%', borderBottom : '0.5px solid rgba(0,0,0,0.1)'}}>
                        <SpacerV h={8}/>
                        <EntryItemText style={{ fontWeight : 400 }}><X>{props.orderData.name}</X></EntryItemText>
                        
                </div>
                
                <div {...css({  ...thumbnail })}>
                    <SpacerV h={20}/>
                        
                    <LinkDelayed to={`${to(Page.PRODUCTS)}/${props.orderData.product}`}>
                        <X>
                        <Button style={{ marginLeft : 0 }}>
                            <div>
                            <div style={{ display : 'block'}}>
                                <img 
                                    style={{
                                        marginTop : 0, 
                                        borderRadius : 5, 
                                        boxShadow: `rgba(0, 0, 0, 0.15) 0px 5px 15px`  
                                    }} 
                                    width={150} 
                                    src={productData.images.back}
                                />
                            </div>
                            <div style={{ display : 'block'}}>
                                <img 
                                    style={{
                                        display : 'block',
                                        marginTop : 0, 
                                        borderRadius : 5, 
                                        boxShadow: `rgba(0, 0, 0, 0.15) 0px 5px 15px`  
                                    }} 
                                    width={150} 
                                    src={productData.images.front}
                                />
                            </div>
                            </div>
                            
                        </Button>
                        </X>
                    </LinkDelayed>
                        
                </div>
               


                <div {...css({ ...product })}>
                    
                    <div {...css({ marginLeft : 25, width : 'calc(100% - 60px)', height : 'auto', backgroundColor : ''})}>
                        
                        <OrderProductItem orderData={props.orderData}/>  

 
                    </div>
                    
                </div>
            
        </OrdersInfoBox>
    )
}












  
  export function SimpleZoom( 
      props : { 
          toggle : boolean 
          setToggle : ( value : boolean ) => void 
    }) {
    

    const icon = (
        <>
            <X>
                <div style={{ width : 16, height : 16, marginTop : -2, transform: "rotate(45deg)", backgroundColor : 'white'}}>
                </div>
            </X>
            <div style={{ position : 'absolute', backgroundColor : 'red', width : 240, height : 100 }}>

                    <p>Nathan Neuhaus</p>
                    <p>1755 CASTLE WAY</p>
                    <p>MOUNTAIN HOME, ID</p>
                    <p>83647-3734</p>
                    <p>US</p>

            </div>
        </>
    );

    return (
        <>{ props.toggle ? icon : <></>}</>
    );
  }

const getTestData = () => {
    const orders = {
        data : [
            {   
                date : 'August 9, 2022',
                id : '112-6969624-7845867',
                total : 27.55,
                product : 'icy-inferno',
                name : 'Icy Inferno',
                shipping : {
                    firstName : 'Nathan',
                    lastName : 'Neuhaus',
                    addressLine1 : '14529 56th ave SE',
                    addressLine2 : '',
                    city : 'Everett',
                    state : 'WA',
                    zipcode : '83647-3734',
                },
                customizations : {

                }
            },
            {   
                date : 'August 9, 2022',
                id : '112-6969624-7845867',
                total : 27.55,
                product : 'icy-inferno',
                name : 'Icy Inferno',
                shipping : {
                    firstName : 'Nathan',
                    lastName : 'Neuhaus',
                    addressLine1 : '14529 56th ave SE',
                    addressLine2 : '',
                    city : 'Everett',
                    state : 'WA',
                    zipcode : '83647-3734',
                },
                customizations : {

                }
            }
        ]
    }
    return orders
}