import React from "react";
import { ItemPrice } from "../checkout/Typography";


const EntryItemText = ( props : { children ?: any, style ?: Record<string,any>}) => {
    return (
        <ItemPrice 
            style={{ 
                fontSize : 14, 
                width : '100%', 
                letterSpacing : 0.2, 
                display : 'inline-block', 
                fontWeight : 300,
                textAlign : 'left',
                ...props.style,
                
            }}
        >
            {props.children}
        </ItemPrice>
    )
}

export default EntryItemText;