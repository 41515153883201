import { css } from "glamor"
import React from "react"
import { LineDividerSpacer } from "../../locust/components/LineDivider"
import { getConfigDefaults, GlobalConfig } from "../../locust/composables/Layout"
import SpacerV from "../../locust/sections/SpacerV"
import { CartItemTitle } from "../checkout/CartItemTitle"
import { OrderDataModel } from "./Models"
import EntryItemText from "../shared/EntryItemText"
import { toDateString } from "./Shared"
import { ENABLE_ERROR_LOGGING } from "../../settings/project-settings"

const SubscriptionItem = ( props : { orderData : OrderDataModel, subscription : { start : number, end : number } } ) => {

    const globals : GlobalConfig = getConfigDefaults()
    const onBreakOne : string = globals.onMedia(580)
    
    
    if( ENABLE_ERROR_LOGGING ) console.log(props.subscription)
    

    const name : Record<string,any> = {
        width : 100,
        [onBreakOne] : {
            display : 'block',
            width : `100%`,
        }
    }

    const value : Record<string,any> = {
        width : `calc(100% - ${100}px)`,
        //backgroundColor : 'red',
        textAlign : 'right',
        [onBreakOne] : {
            display : 'block',
            textAlign : 'left',
            width : `100%`,
        }
    }
    

    const titles = {
        date : "Date",
        total : "Total",
        status : "Status",
        order : "Order",
    }

    return (
        <>

            <CartItemTitle style={{ marginTop : 0, height : 'auto', fontSize : 12, width : '100%', textTransform : 'uppercase', fontWeight : 300, backgroundColor : '' }}>
                    <SpacerV h={24}/>
                    
                    <div style={{ width : '100%', height : 'auto' }}>
                            <EntryItemText style={{ fontWeight : 600, ...name }}>{titles.order}</EntryItemText>
                            <EntryItemText style={{  ...value  }}>{props.orderData.id}</EntryItemText>
                    </div>


                   
                    <LineDividerSpacer top={4} bottom={5}/>
                    
                    <div style={{ width : '100%', height : 'auto' }}>
                            <EntryItemText style={{ fontWeight : 600, ...name }}>{titles.date}</EntryItemText>
                            <EntryItemText style={{  ...value }}>{props.orderData.date}</EntryItemText>
                    </div>


                    
                    <LineDividerSpacer top={4} bottom={5}/>
                    
                    <div style={{ width : '100%', height : 'auto',  }}>
                            <EntryItemText style={{ fontWeight : 600, ...name }}>{titles.total}</EntryItemText>
                            <EntryItemText style={{ ...value  }}>${props.orderData.total}</EntryItemText>
                    </div>

                    <LineDividerSpacer top={4} bottom={5}/>
                    
                    <div style={{ width : '100%', height : 'auto',  }}>
                            <EntryItemText style={{ fontWeight : 600, ...name }}>Previous</EntryItemText>
                            <EntryItemText style={{ ...value  }}>{toDateString(props.subscription.start)}</EntryItemText>
                    </div>

                    <LineDividerSpacer top={4} bottom={5}/>
                    
                    <div style={{ width : '100%', height : 'auto',  }}>
                            <EntryItemText style={{ fontWeight : 600, ...name }}>Next</EntryItemText>
                            <EntryItemText style={{ ...value  }}>{toDateString(props.subscription.end)}</EntryItemText>
                    </div>

            </CartItemTitle>
                        
        </>
    )
}


export default SubscriptionItem;