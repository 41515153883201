import { css } from "glamor"
import React, { useState } from "react"
import { LineDividerSpacer } from "../../locust/components/LineDivider"
import { GlobalConfig, getConfigDefaults, validateConfig } from "../../locust/composables/Layout"
import Y from "../../locust/composables/Y"
import SpacerV from "../../locust/sections/SpacerV"
import { CartItemTitle } from "../checkout/CartItemTitle"
import { OrderDataModel } from "./Models"
import EntryItemText from "../shared/EntryItemText"
import BoxTitle from "../shared/BoxTitle"


const OrderProductItem = ( props : { orderData : OrderDataModel } ) => {

    const globals : GlobalConfig = getConfigDefaults()
    const onBreakOne : string = globals.onMedia(580)
    
    
    //alert(JSON.stringify(props.orderData.shipping))
    

    const name : Record<string,any> = {
        width : 100,
        [onBreakOne] : {
            display : 'block',
            width : `100%`,
        }
    }

    const value : Record<string,any> = {
        width : `calc(100% - ${100}px)`,
        //backgroundColor : 'red',
        textAlign : 'right',
        [onBreakOne] : {
            display : 'block',
            textAlign : 'left',
            width : `100%`,
        }
    }
    

    const titles = {
        date : "Date",
        total : "Total",
        name : "Ship To",
        order : "Order",
    }

    
    return (
        <>

            <CartItemTitle style={{ marginTop : 0, height : 'auto', fontSize : 12, width : '100%', textTransform : 'uppercase', fontWeight : 300, backgroundColor : '' }}>
                    <SpacerV h={24}/>
                    
                    <div style={{ width : '100%', height : 'auto' }}>
                            <EntryItemText style={{ fontWeight : 600, ...name }}>{titles.order}</EntryItemText>
                            <EntryItemText style={{  ...value  }}>{props.orderData.id}</EntryItemText>
                    </div>


                   
                    <LineDividerSpacer top={4} bottom={5}/>
                    
                    <div style={{ width : '100%', height : 'auto' }}>
                            <EntryItemText style={{ fontWeight : 600, ...name }}>{titles.date}</EntryItemText>
                            <EntryItemText style={{  ...value }}>{props.orderData.date}</EntryItemText>
                    </div>


                    
                    <LineDividerSpacer top={4} bottom={5}/>
                    
                    <div style={{ width : '100%', height : 'auto',  }}>
                            <EntryItemText style={{ fontWeight : 600, ...name }}>{titles.total}</EntryItemText>
                            <EntryItemText style={{ ...value  }}>${props.orderData.total}</EntryItemText>
                    </div>

                   
                    <LineDividerSpacer top={4} bottom={5}/>

                    <div style={{ width : '100%', height : 'auto',  }}>
                            <EntryItemText style={{ fontWeight : 600, ...name }}>Ship To:</EntryItemText>
                            <EntryItemText style={{ ...value  }}>
                                    {`${props.orderData.shipping.firstNameShipping} ${props.orderData.shipping.lastNameShipping}`}
                            </EntryItemText>
                    </div>

                    <SpacerV h={2}/>
                    <div style={{ width : '100%', height : 'auto',  }}>
                            <EntryItemText style={{ fontWeight : 600, ...name }}></EntryItemText>
                            <EntryItemText style={{ ...value  }}>
                                {props.orderData.shipping.addressShipping} 
                            </EntryItemText>
                    </div>

                    {
                        (props.orderData.shipping.addressShippingTwo !== "" && props.orderData.shipping.addressShippingTwo !== undefined) && 
                        <>
                            <SpacerV h={2}/>
                            <div style={{ width : '100%', height : 'auto',  }}>
                                    <EntryItemText style={{ fontWeight : 600, ...name }}></EntryItemText>
                                    <EntryItemText style={{ ...value  }}>
                                        {props.orderData.shipping.addressShippingTwo}
                                    </EntryItemText>
                            </div>
                        </>
                    }

                    <SpacerV h={2}/>
                    <div style={{ width : '100%', height : 'auto',  }}>
                            <EntryItemText style={{ fontWeight : 600, ...name }}></EntryItemText>
                            <EntryItemText style={{ ...value  }}>
                            {`${ props.orderData.shipping.cityShipping}, ${ props.orderData.shipping.stateShipping} ${props.orderData.shipping.zipcodeShipping}`} 
                            </EntryItemText>
                    </div>
                    
                
            </CartItemTitle>
                        
        </>
    )
}

export default OrderProductItem;





const OrderProductV1 = ( props : {
    shippingFirstName : string,
    shippingLastName : string, 
    orderDate : string, 
    orderNumber : string,
    itemTotal : string,

}) => {
    const [ toggle, setToggle ] = useState(false)
    const tabHeight : number = 70 
    const globals : GlobalConfig = validateConfig()
    const onBreakOne : string = globals.onMedia(1000)
    const onBreakTwo : string = globals.onMedia(620)
    
    const shared : Record<string,any> = {
        width : 'calc(25% - 5px)',
        display: 'inline-block', 
        backgroundColor : '', 
        height : tabHeight,
        verticalAlign : 'top',
        [ onBreakOne] : {
            //backgroundColor : 'red',
            width : '50%',
        },
        [ onBreakTwo] : {
            display : 'block',
            width : '100%',
        }
    }

    const itemTitles = {
        date : "Order Placed",
        total : "Total",
        name : "Ship To",
        order : "Order",
    }

    return (
        <>

            <CartItemTitle style={{ marginTop : 0, height : 'auto', fontSize : 12, width : '100%', textTransform : 'uppercase', fontWeight : 300, backgroundColor : '' }}>
                
                    <div {...css({ 
                            ...shared,
                    })}>
                            <Y>
                            <SpacerV h={20}/>
                            <BoxTitle>{itemTitles.date}</BoxTitle>
                            <SpacerV h={4}/>
                            <BoxTitle 
                                style={{ marginTop : -12 }}>
                                    <p style={{ fontWeight : 300 }}>{props.orderDate}</p>
                                    
                            </BoxTitle>
                            </Y>
                        
                    </div>
                    
                    <div {...css({ 
                            ...shared,
                            marginLeft : 20,
                            [ onBreakOne ] : {
                                marginLeft : 0,
                                //backgroundColor : 'purple',
                                width : '50%'

                            }
                            
                    })}>
                            <Y>
                            <SpacerV h={20}/>
                            <BoxTitle 
                                style={{ 
                                    [ onBreakOne ] : {
                                        
                                        justifyContent : 'right'
                                    },
                                    [ onBreakTwo ] : {
                                        
                                        justifyContent : 'left'
                                    },
                                    
                                }}>
                                    
                                    {itemTitles.total}
                                    
                                    
                            </BoxTitle>
                            <SpacerV h={4}/>
                            <BoxTitle 
                                style={{ 
                                    marginTop : -12, 
                                    [ onBreakOne ] : {
                                        
                                        justifyContent : 'right'
                                    },
                                    [ onBreakTwo ] : {
                                        
                                        justifyContent : 'left'
                                    },
                                }}>
                                    <p style={{ fontWeight : 300 }}>
                                        {props.itemTotal}
                                    </p>
                                    
                            </BoxTitle>
                            </Y>
                    </div>

                    <div
                        {...css({ 
                            ...shared,
                        })} 
                        onMouseEnter={()=>{ setToggle(true) }} 
                        onMouseLeave={()=>{ setToggle(false) }} 
                    >
                            <Y>
                            <SpacerV h={20}/>
                            <BoxTitle>{itemTitles.name}</BoxTitle>
                            <SpacerV h={4}/>
                            <BoxTitle 
                                style={{ }}>
                                    <p style={{ fontWeight : 300, marginTop : -12,  }}>
                                        {`${props.shippingFirstName} ${props.shippingLastName}`}
                                    </p>
                                    {/*<SimpleZoom toggle={toggle} setToggle={ setToggle }/>*/}
                            </BoxTitle>
                            </Y>
                            
                            
                    </div>

                    <div {...css({ 
                            ...shared,

                    })}>
                            <Y>
                            <SpacerV h={20}/>
                            <BoxTitle>
                                
                            </BoxTitle>
                            <BoxTitle 
                                style={{  
                                    width : '100%', 
                                    backgroundColor : '', 
                                    justifyContent : 'right',
                                    [ onBreakTwo ] : {
                                        justifyContent : 'left'
                                    },
                                }}>
                                    {itemTitles.order}
                            </BoxTitle>

                            <SpacerV h={4}/>
                            <BoxTitle 
                                style={{ 
                                    width : '100%', 
                                    backgroundColor : '', 
                                    justifyContent : 'right',
                                    marginTop : -12, 
                                    [ onBreakTwo ] : {
                                        
                                        justifyContent : 'left'
                                    }
                                }}>
                                    <p style={{ fontWeight : 300 }}>
                                        {props.orderNumber}
                                    </p>
                                    {/*<SimpleZoom toggle={toggle} setToggle={ setToggle }/>*/}
                            </BoxTitle>
                            </Y>
                    </div>
                
            </CartItemTitle>
                        
        </>
    )
}
