import { Button } from "@mui/material"
import { css } from "glamor"
import React from "react"
import { getConfigDefaults, GlobalConfig } from "../../locust/composables/Layout"
import LinkDelayed from "../../locust/composables/LinkDelayed"
import X from "../../locust/composables/X"
import SpacerV from "../../locust/sections/SpacerV"
import { OrderDataModel } from "./Models"
import OrdersInfoBox from "./OrdersInfoBox"
import EntryItemText from "../shared/EntryItemText"
import SubscriptionItem from "./SubscriptionItem"
import ProtectionPlanIcon from '../../products/shared/images/icon-protection.svg'
import SubscriptionIcon from '../../products/shared/images/icon-calendar.svg'


import XY from "../../locust/composables/XY"


export const ProductThumbnail = ( props : {type : string } ) => {
    const type : string = props.type
    

    return <></>
    const isProtection : boolean = 
        type === "Protection Plan" ||
        type.toLowerCase() === "protection"

    const isSubscription : boolean = 
        type === "Subscription" ||
        type.toLowerCase() === "subscription"

    if( isProtection ) return (
        <>
            <XY>
                <img 
                    style={{
                        marginTop : 20, 
                        borderRadius : 5, 
                        border : '1px solid rgba(0,0,0,0.1)',
                        padding : 5,
                        boxShadow: `rgba(0, 0, 0, 0.15) 0px 5px 15px`  
                    }} 
                    width={50} 
                    height={50} 
                    src={ProtectionPlanIcon}
                />
            </XY>
        
        </>
    )
    if( isSubscription ) return (
        <>
            <XY>
                <img 
                    style={{
                        marginTop : 20, 
                        borderRadius : 5, 
                        border : '1px solid rgba(0,0,0,0.1)',
                        padding : 5,
                        boxShadow: `rgba(0, 0, 0, 0.15) 0px 5px 15px`  
                    }} 
                    width={50} 
                    height={50} 
                    src={SubscriptionIcon}
                />
            </XY>
        
        </>
    )
    return <>nothing</>
}


const OrderSubscription = ( props :{ orderData : OrderDataModel, type : string, subscription : { start : number, end : number } }) => {

    
    //if( ENABLE_ERROR_LOGGING ) console.log(props.subscription)
    //alert(props.orderData.product)
    const height = 'auto'   
    //const productData : any =  getIcyInferno('icy-inferno')
    const globals : GlobalConfig = getConfigDefaults()
    const onBreakOne : string = globals.onMedia(500)
    const onBreakTwo : string = globals.onMedia(300)
    
    const thumbnail = {
        verticalAlign : 'top',
        width : 200, 
        display : 'inline-block',
        backgroundColor : '',
        minHeight : 100,
        height : '100%',
        [onBreakOne]:{ 
            backgroundColor : '',
            width : '100%',
        }, 
        
    }
    const product = {
        verticalAlign : 'top',
        marginLeft: -20,
        width : `calc(100% - ${180}px)`, 
        display : 'inline-block',
        minHeight : 100,
        backgroundColor : '',
        [onBreakOne]:{ 
            backgroundColor : '',
            width : '100%',
            marginLeft : 10 
        }, 
        
    }
    
    return (
        <OrdersInfoBox style={{ 
            height : height, 
            paddingBottom : 20, 
            width : 'calc(100% - 45px)', 
            maxWidth : '',
            [onBreakTwo] : {
                //backgroundColor : 'purple',
                width : 360 - 60,
            }
        }}>
            
                <div style={{ height : 40, width : '100%', borderBottom : '0.5px solid rgba(0,0,0,0.1)'}}>
                        <SpacerV h={8}/>
                        <EntryItemText style={{ fontWeight : 400 }}><X>{props.orderData.name}</X></EntryItemText>
                        
                </div>
                
                <div {...css({  ...thumbnail })}>
                    <SpacerV h={20}/>
                        
                    <div style={{ display : 'block'}}>
                        <ProductThumbnail type={props.type}/>
                    </div>
                        
                </div>
               


                <div {...css({ ...product })}>
                    
                    <div {...css({ marginLeft : 25, width : 'calc(100% - 60px)', height : 'auto', backgroundColor : ''})}>
                        
                        
                        <SubscriptionItem orderData={props.orderData} subscription={props.subscription}/>  
                        

 
                    </div>
                    
                </div>
            
        </OrdersInfoBox>
    )
}

export default OrderSubscription;