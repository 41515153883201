import React from 'react'
import { BreadCrumb } from '../../locust/components/BreadCrumb'
import { HeaderSpacer } from '../../locust/components/Header'
import { LayoutFS } from '../../locust/composables/Layout'
import { getShoppingCartLength } from '../../service/shopping-cart'
import { ClientFooter } from './ClientFooter'
import CobraHeader, { CobraHeaderTypes } from './CobraHeader'


const CobraLayout = (props : {
    children ?: any,
    breadcrumb ?: { value : string, url : string }[],
    disableBreadCrumb ?: boolean, 
    shoppingCartLength ?: number  
    loggedIn ?: boolean
    noredirect ?: boolean 
    headerType : CobraHeaderTypes,
    style ?: Record<string,any>
    contactsData ?:  any,
    setContactsData ?: ( contactsData : any ) =>void
    currentCardId ?: string,
    setCurrentCardId ?: ( currentCard : string ) => void,
}) => {

    const hasBreadcrumb : boolean = !props.disableBreadCrumb && props.breadcrumb !== undefined
    return (
        <>
            <LayoutFS min={350}>
                <div style={{ minHeight : '100vh', ...props.style }}>
                    <CobraHeader 
                        disableBreadCrumb={props.disableBreadCrumb}
                        contactsData={props.contactsData}
                        setContactsData={props.setContactsData}
                        currentCardId={props.currentCardId}
                        setCurrentCardId={props.setCurrentCardId}
                        type={props.headerType}
                        loggedIn={props.loggedIn}
                        shoppingCart={ 
                        props.shoppingCartLength !== undefined 
                            ? props.shoppingCartLength 
                            : getShoppingCartLength()
                    }/>
                    { hasBreadcrumb && 
                        <div style={{ marginTop : - 60 }}>
                            <HeaderSpacer/>
                            <BreadCrumb links={props.breadcrumb as any}
                            />
                        </div>
                    }
                    { props.children }
                </div>
                <ClientFooter/>
            </LayoutFS>
        
        </>
        
    )
}
export default CobraLayout;