import React from 'react'
import X, { Alignment } from '../../locust/composables/X'
import SpacerV from '../../locust/sections/SpacerV'
import { ItemPrice } from '../checkout/Typography'

export default ( props : { children ?: any, align ?: Alignment, style?: Record<string,any> }) => {

    return (
        <>
        <X align={ props.align ? props.align : "left"} style={{ height : 'auto', width : '100%', ...props.style }}>   
            <ItemPrice 
                style={{ fontSize : 14, fontWeight : 600, letterSpacing : 0.2, display : 'inline-block' }}
            >
                { props.children }
            </ItemPrice>
        </X>
        <SpacerV h={10}/>
        </>
    )
}