import { Link } from "gatsby"
import { css } from "glamor"
import React from "react"
import { FACEBOOK_BLACK, FACEBOOK_SKYBLUE } from "../../assets/colors/Colors"
import { SpacerVRsp } from "../../locust/composables/SpacerVRsp"
import X from "../../locust/composables/X"
import XY from "../../locust/composables/XY"
import SpacerV from "../../locust/sections/SpacerV"
import { CartItemTitle } from "../checkout/CartItemTitle"
import { SectionTitle } from "../checkout/CheckoutSectionTitle"
import { CheckoutWrapperWhiteBG } from "../checkout/shared"


const NoticeAndLink = ( props : { children ?: any,
    value : string,
    href ?: string,
    onClick ?: () => void 
}) => {

    const linkStyle : Record<string,any> = {
        cursor : 'pointer', 
        color : FACEBOOK_BLACK,
        ':hover' : { color : '#1877F2'}, 
        ':active' : { color : FACEBOOK_SKYBLUE}
    }
    
    return (
        <>
            <X>
                <div onClick={props.onClick}{...css({ 
                    
                    width : 'calc(100% - 60px)',
                    maxWidth : 760,

                })}>
                <CheckoutWrapperWhiteBG style={{ paddingLeft : 20, paddingRight : 20, borderRadius: 6  }}>
                    <XY>
                        
                        <SpacerV h={20}/>
                        <SpacerVRsp breakpoint={351} before={15} after={30}/>
                        
                        <SectionTitle style={{ width : '100%', marginTop : -30, backgroundColor : ''}}>
                            <p style={{ fontSize : 16, fontWeight : 800, textAlign : 'center' }}>
                                
                                {props.children}
                            </p>
                        </SectionTitle> 
                    
                    
                        <SpacerV h={20}/>
                        <Link to={props.href ? props.href : "" } {...css({ 
                            position: 'absolute', marginTop : 50, fontSize : 14, ':link' : { textDecoration : 'none'}})}>
                            <CartItemTitle style={{ marginTop : - 20, ...linkStyle }}>{props.value}</CartItemTitle>
                        </Link>
                    
                    </XY>
                </CheckoutWrapperWhiteBG>
                </div>
            </X>

        </>
    )
}

export default NoticeAndLink;