
import { css } from "glamor";
import React, { useState } from "react";
import { ShoppingCart } from "styled-icons/material-outlined";
import { AuthLogo, SubtitleIIRsp } from "../../locust/components/Auth";
import { BadgeNumbered } from "../../locust/components/BadgeNumbered";
import { HeaderMenu, HeaderMenuItem, HeaderMenuDivider} from "../../locust/components/Header";
import X from "../../locust/composables/X";
import XY from "../../locust/composables/XY";
import SpacerV from "../../locust/sections/SpacerV";
import { CircularProgress, Link } from '@mui/material'
import Avatar from '@mui/material/Avatar';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import HubIcon from '@mui/icons-material/Hub';
import {Dashboard} from '@styled-icons/boxicons-solid/Dashboard'
import Logout from '@mui/icons-material/Logout';
import { HeaderTemplateLCR } from "../../locust/template/HeaderTemplateLCR";
import { auth, isLoggedIn } from "../../../firebase-config";
import { NavigateLink } from "../../locust/components/BreadCrumb";
import { getRandomString, __, isOnMainPage } from "../../locust/composables/Shared";
import Y from "../../locust/composables/Y";
import { FACEBOOK_BLUE } from "../../assets/colors/Colors";
import { Page, to } from "./SiteLinks";
import { BadgeAndDropdown, DropDownNotificationMenu } from "../../locust/components/BadgeAndDropdown";
import { navigate } from "gatsby";
import { portalAuthStorage00 } from "../../../firebase/firebase-portal-storage-00";


export type CobraHeaderTypes = 'store' | 'dashboard' | 'barebones'

const CobraHeader = (props : { 
    disableBreadCrumb ?: boolean, 
    loggedIn ?: boolean,
    shoppingCart ?: number ,
    type : CobraHeaderTypes,
    contactsData ?: any,
    setContactsData ?: ( contactsData : any ) => void,
    currentCardId ?: string | undefined,
    setCurrentCardId ?: ( currentCard : string ) => void,
}) => {

    const signin : string = `Sign${__}In`
    const isStore : boolean = props.type === 'store'
    const isDashboard : boolean = props.type === 'dashboard'
    const isBarebones : boolean = props.type === 'barebones'

    const [ token, setToken]            = useState<any>(undefined)
    //const [ responses, setResponses ]   = useState<any>(undefined)
    const [ loading, setLoading ]       = useState(true)
    const [ alertDropdown, setAlertDropdown] = useState(false)
    const [ menuOffset, setMenuOffset] = useState({ x : 0 , y : 0 })
    const [headerLink, setHeaderLink ] = useState(<XY><CircularProgress style={{ height : 20, width : 20, color : 'white'}}/></XY>)

    const [ contactsBoard, setContactsBoard ] = useState<any>()

    React.useEffect(()=>{
        

        ( async ()=>{
            
            if(props.contactsData)
                setContactsBoard(
                    <div key={getRandomString()}>
                        <BadgeAndDropdown currentCardId={props.currentCardId} loading={loading} contactsData={props.contactsData} token={token} setContactsData={ props.setContactsData ? props.setContactsData : ()=>{}}/>
                        <div/>
                            { props.contactsData === null && <>No New Contact Requests!</>}
                            { props.contactsData && <div key={getRandomString()}>                    
                        </div>}
                        <DropDownNotificationMenu toggle={alertDropdown} setToggle={setAlertDropdown} menuOffset={menuOffset}/>
                    </div>
                )
        })()
        
    },[ props.contactsData ])
    
    React.useEffect(()=>{
        auth.onAuthStateChanged( async ()=>{
            const loggedIn : boolean = isLoggedIn()
            const emailVerified : boolean| undefined = auth.currentUser?.emailVerified
            const isOnPublicPage : boolean = 
                isOnMainPage() ||
                window.location.href.includes("/index") ||
                window.location.href.includes("/index-2") ||
                window.location.href.includes("/checkout") ||
                window.location.href.includes("/products") ||
                window.location.href.includes("/bus") ||
                window.location.href.includes("/auth")             
            if( loggedIn && !isOnPublicPage && !emailVerified) navigate("/auth/unregistered")
            else setToken( await auth.currentUser?.getIdToken() as string ) 
        })
    },[ ])

    
    
    React.useEffect(()=>{
        if(token)
            setHeaderLink( <div style={{ height : '100%', width : 30, backgroundColor : '', verticalAlign : 'top'}}>
                <XY>
                    <CobraAccountMenu/>
                </XY>
            </div>)
        else setHeaderLink(
            isBarebones ? <></> : 
            <div style={{ height : '100%'}}>
                <Y>
                    
                    <NavigateLink 
                        disable={props.disableBreadCrumb}
                        href="/auth/sign-in" 
                        color="white" 
                        hover={ FACEBOOK_BLUE }
                        style={{ fontWeight : 500 }}
                    >
                        {signin}
                    </NavigateLink> 
                </Y>
            </div>
        )
    },[ token ])


  

    return (
        <>
            <HeaderTemplateLCR r={{ width : 140 }}>
                {/* LEFT SECTION (AUTO-POPULATE) */}
                <Link href={to(Page.HOME)} {...css({ ':link' : { textDecoration : 'none', backgroundColor : ''}})}>
                    <div style={{ height : 70, width : 70, display : 'inline-block' }}>
                        <SpacerV h={10}/>
                        <AuthLogo size={60} color={'white'}/>
                    </div>
                </Link>

                {/* CENTER SECTION (AUTO-POPULATE) */}
                <XY>
                    <Link href="/dashboard" {...css({ ':link' : { textDecoration : 'none', backgroundColor : ''}})}>
                        <SubtitleIIRsp c={'white'} style={{ width : 200 }}>SLAP KARD!</SubtitleIIRsp>
                    </Link>
                </XY>
            
                {/* RIGHT SECTION (AUTO-POPULATE) */}
                <X> 

                    { isBarebones ? <></> : headerLink}


                    { isStore && 
                        <Link href="/checkout" style={{ width : '100%'}}>
                            <X style={{ marginTop : 16 }}>
                                <ShoppingCart color={"white"} size={25}/>
                                <BadgeNumbered>{props.shoppingCart ? props.shoppingCart : 0 }</BadgeNumbered>
                            </X>
                        </Link>
                    }

                    { isDashboard && <> {contactsBoard}</>}
                </X>
            </HeaderTemplateLCR>
        </>
    )
}
export default CobraHeader;


function parseResponses( responses : any , entryMap : any  ){
    const items : any[] = []
    responses.forEach(( rsp : any )=>{   
        let item = {}
        Object.keys(rsp.answers).forEach(( id )=>{
            const raw = rsp.answers[id].textAnswers.answers[0].value
            const rawSplit = raw.split(':')
            const entryId = rawSplit[0]
            const name = entryMap[entryId]
            const value = rawSplit[1]
            item = { ...item, [name] : value }
        })
  
        item = { ...item, Date : rsp.lastSubmittedTime }
        items.push( item )
        
    })
    return items    
}

const getFormEntryMap = ( objectToMap : any ) => {
    let entryMap = {}
    objectToMap.forEach(( entry : any )=>{
        entryMap = {
            ...entryMap,
            [entry.id] : entry.value
        }
    })
    return entryMap
}
 
export const CobraAccountMenu = () => {
    
    const [avatarLetter, setAvatarLetter] = React.useState("")
    
    React.useEffect(()=>{
        
        auth.onAuthStateChanged(()=>{
            if( auth.currentUser ){
                const letter : string = auth.currentUser && auth.currentUser.email 
                    ? auth.currentUser.email 
                    : ""

                    setAvatarLetter(letter.substring(0,1).toUpperCase())
                    
            }      
        })
    },[])

    return (
        <>
            <HeaderMenu tooltip={'Account Settings'} avatarLetter={avatarLetter}>
                <HeaderMenuItem 
                    href={'/profile'}
                    icon={<Avatar/>}
                    >
                        Profile
                </HeaderMenuItem>
                
                <HeaderMenuDivider />

                 <HeaderMenuItem 
                    href={'/dashboard'}
                    icon={<Dashboard size={18}/>}
                >
                    Dashboard
                </HeaderMenuItem>
                
                <HeaderMenuItem 
                    href={'/orders'}
                    icon={<LocalShippingIcon fontSize="small" />}
                >
                    Orders
                </HeaderMenuItem>
                
                {/* <HeaderMenuItem 
                    href={'/network'}
                    icon={<HubIcon fontSize="small" />}
                >
                    Network
                </HeaderMenuItem> */}




                <HeaderMenuItem   
                    onClick={async ()=>{ 
                        await auth.signOut() 
                        await portalAuthStorage00.signOut()
                        auth.onAuthStateChanged(()=>{
                            navigate("/")
                        })
                    
                    }}
                    icon={<Logout fontSize="small" />}
                >
                    Logout
                </HeaderMenuItem>


            </HeaderMenu>
        </>
    )
}