import { doc, getDoc } from "firebase/firestore"
import { auth, db } from "../../../firebase-config"


export const getOrdersFromRemote = ( props : { 
    onSuccess ?: ( response : any ) => void 
}) => {
    const uid : string = auth.currentUser?.uid as string
    getDoc(doc(db, `orders/${uid}`)).then(( response )=>{
        if( props.onSuccess )
            props.onSuccess( response.data() )
    })
}

export const getCustomerDataFromRemote = ( props : { 
    onSuccess ?: ( response : any ) => void 
}) => {
    const uid : string = auth.currentUser?.uid as string
    getDoc(doc(db, `customers/${uid}`)).then(( response )=>{
        if( props.onSuccess )
            props.onSuccess( response.data() )
    })
}

export const toDateString = ( secs : number ) => {
    var t = new Date(1970, 0, 1); // Epoch
    t.setSeconds(secs);

    const monthNames : string[] = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    const month : string = monthNames[t.getMonth()]
    const day : string = String(t.getDay())
    const year : string = String(t.getFullYear())
    return `${month} ${day}, ${year}`;
}