import { navigate } from "gatsby"
import React from "react"
import { auth, isLoggedIn } from "../../../firebase-config"



const AuthManager = ( props : {  noredirect ?: boolean, setLoggedIn : ( value : boolean ) => void  }) => {

    const redirect : boolean = props.noredirect === undefined || props.noredirect === false
    React.useEffect(()=>{
        
        auth.onAuthStateChanged(()=>{
            if(isLoggedIn()) props.setLoggedIn(true)
        })

        if(redirect)
            setTimeout(()=>{
                //if(!isLoggedIn()) 
                    //navigate('/auth/sign-in');
            },7500)
    },[])

return (<></>)
}

export default AuthManager




const PageUsageExample = () => {

    const [loggedIn, setLoggedIn ] = React.useState(false)

    return (
        <>
            <AuthManager setLoggedIn={setLoggedIn}/>
            
            {/* Then inject as follows

                <CobraLayout headerType="store" loggedIn={loggedIn}>
                    <Dashboard loggedIn={loggedIn}/>
                </CobraLayout>
            */}
        </>
    )
}