import React from "react"
import { BorderedBox } from "../../locust/components/BorderedBox"

const OrdersInfoBox = ( props : { children ?: any, style ?: Record<string,any> }) => {
    return (
        <BorderedBox 
                style={{ 
                    maxWidth : 1000,
                    marginLeft : 0, 
                    //padding : 20,
                    borderRadius : 4,
                    margin: 20,
                    ...props.style
                }
            }>    
            { props.children }
        </BorderedBox>
    )
}

export default OrdersInfoBox;

