import { css } from "glamor"
import React from "react"
import { getConfigDefaults } from "./Layout"

export const SpacerVRsp = ( props : { 
    breakpoint : string | number, 
    before : number | string, 
    after : number | string 
}) => {
    const on = ( value : number ) => getConfigDefaults().onMedia( value )
    const breakpoint : string = typeof props.breakpoint === 'number' ? on(props.breakpoint) : props.breakpoint
    return <div 
        {
            ...css({ 
                width : '100%', 
                height : props.before, 
                [breakpoint]:{ 
                    height : props.after 
                }
            })
        }
    />
}